import Axios from 'axios'
import { useReCaptcha as _useReCaptcha } from 'vue-recaptcha-v3'

export function useRecaptcha (action) {
  const { executeRecaptcha, recaptchaLoaded } = _useReCaptcha()

  const getToken = async () => {
    await recaptchaLoaded()

    return await executeRecaptcha(action)
  }

  const validate = async (captcha_token) => {
    if ((captcha_token?.length || 0) < 1) {
      captcha_token = await getToken()
    }

    const response = await Axios({
      url: '/recaptcha',
      method: 'POST',
      data: { captcha_token },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return response?.data?.valid
  }

  return { getToken, validate }
}
